<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const gridClass = computed(() => {
  let parent = "";
  let columns = [];
  let buttonWrapperClass = ["2xl:grid-cols-2", "2xl:grid-cols-2"];

  switch (props.blok.columnWidth) {
    case "1:1":
      parent = "grid md:grid-cols-2";
      columns = ["lg:grid-cols-1", "lg:grid-cols-1"];
      break;
    case "2:1":
      parent = "grid md:grid-cols-3";
      columns = ["md:col-span-2", ""];
      buttonWrapperClass = ["lg:grid-cols-2", "lg:grid-cols-1"];
      break;
    case "1:2":
      parent = "grid md:grid-cols-3";
      columns = ["", "md:col-span-2"];
      buttonWrapperClass = ["lg:grid-cols-1", "lg:grid-cols-2"];
      break;
  }

  return { parent, columns, buttonWrapperClass };
});
</script>

<template>
  <Container
    :style="{ backgroundColor: blok?.backgroundColor ?? 'inherit' }"
    :default-padding="false"
    container-class="px-2.5 lg:px-10 2xl:px-20 md:px-5 pb-10 pt-8 lg:pt-0 lg:pb-0"
  >
    <div
      v-editable="blok"
      class="flex flex-col py-2.5 pb-5 gap-5 lg:gap-[60px] lg:py-20"
    >
      <div
        v-if="blok.subtitle || blok.heading || blok.text"
        class="max-w-[700px] lg:py-5 py-[30px] px-2.5 md:px-0"
      >
        <p v-if="blok.subtitle" class="font-helvetica-bold mb-[5px]">
          {{ blok.subtitle }}
        </p>
        <h2 class="text-[25px] lg:text-[33px] font-helvetica-light mb-5">
          {{ blok.heading }}
        </h2>
        <p class="text-[19px] lg:text-[21px] font-calson">
          {{ blok.text }}
        </p>
      </div>
      <div
        class="gap-[30px] 2xl:gap-[60px] items-center"
        :class="gridClass.parent"
        :style="{ backgroundColor: blok?.gutterColor ?? 'inherit' }"
      >
        <ColumnItem
          v-for="(column, index) in blok.columns"
          :key="column._uid"
          class="self-stretch"
          :class="gridClass.columns[index]"
          :button-wrapper-class="gridClass.buttonWrapperClass[index]"
          :blok="column"
          :font-style="blok.fontStyle"
          :add-margin-right="index === 1 && !!blok.gutterColor"
          :add-margin-left="index === 0 && !!blok.gutterColor"
        />
      </div>
    </div>
  </Container>
</template>
